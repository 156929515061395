function c(r) {
  document.readyState !== "loading" ? r() : document.addEventListener("DOMContentLoaded", () => r());
}
function f() {
  return new Promise(function(r) {
    c(r);
  });
}
function n(r, e, t, s) {
  return r.addEventListener(e, t, s), () => {
    r.removeEventListener(e, t, s);
  };
}
class m {
  constructor(e = "#errorText", t = "#error") {
    this.errLabel = null, this.errDisplay = null, this.hasFocus = !1, this.errLabel = document.querySelector(e), this.errDisplay = document.querySelector(t);
  }
  get canDisplayError() {
    return this.errLabel && this.errDisplay;
  }
  checkError() {
    if (!this.canDisplayError)
      throw new Error("Error element not present");
    if (this.errLabel && this.errLabel.innerHTML && this.errDisplay) {
      this.errDisplay.style.display = "";
      const e = this.errLabel.getAttribute("for");
      if (e) {
        const t = document.getElementById(e);
        t && t.value && (t.focus(), this.hasFocus = !0);
      }
    } else
      this.errDisplay && (this.errDisplay.style.display = "none");
  }
  setInitialFocus(e) {
    if (this.hasFocus)
      return;
    const t = document.getElementById(e);
    t && /^\s*$/.test(t.value) && (t.focus(), this.hasFocus = !0);
  }
  setError(e, t) {
    if (!this.canDisplayError)
      throw new Error("Error element not present");
    e.focus(), this.errLabel && (t && (this.errLabel.innerHTML = t), this.errLabel.setAttribute("for", e.id)), this.errDisplay && (this.errDisplay.style.display = "");
  }
  clearError() {
    if (!this.canDisplayError)
      throw new Error("Error element not present");
    this.errLabel && (this.errLabel.innerHTML = "", this.errLabel.setAttribute("for", ""), this.errDisplay && (this.errDisplay.style.display = "none"));
  }
  clearValidationErrors() {
    document.querySelectorAll("div.error > span").forEach((t) => {
      t.innerHTML = "";
    });
  }
  setValidationError(e, t, s = !1) {
    const i = e.id;
    s && e.focus();
    const l = document.querySelector(
      `#${i} ~ div.error > span`
    );
    l && (l.innerHTML = t);
  }
  focus(e) {
    const t = document.getElementById(e);
    t && t.focus();
  }
  hasClass(e, t) {
    return e.className.match(new RegExp("(\\s|^)" + t + "(\\s|$)"));
  }
  addClass(e, t) {
    this.hasClass(e, t) || (e.className += " " + t);
  }
  removeClass(e, t) {
    if (this.hasClass(e, t)) {
      const s = new RegExp("(\\s|^)" + t + "(\\s|$)");
      e.className = e.className.replace(s, " ");
    }
  }
  showHint(e) {
    if (e.value && e.value !== "") {
      const t = e.previousSibling;
      t.style.display = "none";
    } else {
      const t = e.previousSibling;
      t.style.display = "";
    }
  }
}
class p {
  constructor(e, t, s, i, l, o, a = null, u = "#errorText", h = "#error", d = null) {
    this.form = e, this.submitButton = t, this.loader = s, this.errors = i, this.validateForm = o, this.errorsConfig = a, this.errTextElementSelector = u, this.errDisplayElementSelector = h, this.submitForm = d, this.requiredFields = [], this.optionalFields = [], this.clearHandlers = [], this.isFormLocked = !1, this.isSubmitButtonEnabled = !1, this.inputUtil = new m(
      this.errTextElementSelector,
      this.errDisplayElementSelector
    ), this.setInputFields(l), this.requiredFields.length < 1 && (this.isSubmitButtonEnabled = !0), this.addEventListeners();
  }
  setInputFields(e) {
    e ? Array.isArray(e) ? (this.requiredFields = e, this.optionalFields = []) : "requiredFields" in e ? (this.requiredFields = e.requiredFields, this.optionalFields = e.optionalFields) : (this.requiredFields = [e], this.optionalFields = []) : (this.requiredFields = [], this.optionalFields = []);
  }
  async addEventListeners() {
    if (await f(), this.form) {
      const e = n(this.form, "keypress", (t) => {
        t.key === "Enter" && (t.preventDefault(), this.submitRequest());
      });
      this.clearHandlers.push(e), this.form.addEventListener("submit", (t) => {
        this.submitForm && (t.preventDefault(), t.stopPropagation());
      });
    }
    if (this.submitButton) {
      const e = n(
        this.submitButton,
        "keypress",
        (s) => {
          s.key === " " && (s.preventDefault(), this.submitRequest());
        }
      );
      this.clearHandlers.push(e);
      const t = n(this.submitButton, "click", () => {
        this.submitRequest();
      });
      this.clearHandlers.push(t);
    }
    this.requiredFields.forEach((e) => {
      this.listenInputField(e, !0);
    }), this.optionalFields.forEach((e) => {
      this.listenInputField(e, !1);
    });
  }
  listenInputField(e, t) {
    if (e) {
      this.errorsConfig?.errorElement && this.errorsConfig?.on === "input" && n(
        e,
        "input",
        () => {
          this.errorsConfig?.errorElement && (this.errorsConfig.errorElement.innerHTML = "");
        },
        { once: !0 }
      );
      const s = n(e, "input", () => {
        window.hasModelErrors = !1, t ? this.checkSubmitButtonState(e) : this.checkSubmitButtonState();
      });
      this.clearHandlers.push(s);
    }
  }
  dispose() {
    this.clearHandlers.forEach((e) => e());
  }
  [Symbol.dispose]() {
    this.dispose();
  }
  validate(e, t) {
    if (typeof this.validateForm == "function")
      return this.validateForm(e, t);
    const s = this.validateForm.failEarly ?? !0;
    let i = !0, l = !0;
    for (const o of this.validateForm.validators)
      if (o.validator(o?.input?.value)) {
        if (t ? (o.onError?.(), this.inputUtil.setValidationError(o.input, o.error, l), l = !1) : this.inputUtil.clearValidationErrors(), i &&= !1, s)
          return !1;
      } else
        i &&= !0;
    return i;
  }
  submitRequest() {
    this.errorsConfig?.errorElement && this.errorsConfig?.on === "submit" && (this.errorsConfig.errorElement.innerHTML = ""), this.validate(this.inputUtil, this.errors) && (this.loader.style.display = "inline-block", this.submitForm === null ? this.isSubmitButtonEnabled && this.submitButton.click() : this.submitForm()), typeof window.history.replaceState == "function" && window.history.replaceState(null, "", window.location.href);
  }
  checkSubmitButtonState(...e) {
    const t = this.validate(this.inputUtil), s = e.every(
      (i) => !!(i.value && i.value.length > 0)
    );
    t && s ? (this.inputUtil.clearValidationErrors(), this.enableButtons(this.submitButton)) : this.disableButtons(this.submitButton);
  }
  enableButtons(...e) {
    e.forEach((t) => {
      t.classList.remove("disabled"), t.removeAttribute("disabled"), t === this.submitButton && (t.classList.remove("selected"), this.isSubmitButtonEnabled = !0);
    });
  }
  disableButtons(...e) {
    e.forEach((t) => {
      t.classList.add("disabled"), t.setAttribute("disabled", "disabled"), t === this.submitButton && (this.isFormLocked && this.isSubmitButtonEnabled && t.classList.add("selected"), this.isSubmitButtonEnabled = !1);
    });
  }
  lock() {
    this.isFormLocked = !0, this.disableButtons(...document.querySelectorAll("button")), this.requiredFields.concat(this.optionalFields).forEach((e) => e?.setAttribute("disabled", "disabled"));
  }
  unlock() {
    this.enableButtons(...document.querySelectorAll("button")), this.requiredFields.concat(this.optionalFields).forEach((e) => e?.removeAttribute("disabled")), this.loader.style.display = "none", this.isFormLocked = !1;
  }
}
export {
  p as ActionFormHelper,
  m as InputUtil
};
